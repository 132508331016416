/**
 * This controls all the side menu logic, rendering the children in the middle with the side menu, if visible.
 */

import { BoardFragmentFragment } from '@apps/www/src/__generated__/graphql';
import {
	getTopNavBottomPosition,
	subscribeToTopNavBottomPosition,
	unsubscribeFromTopNavBottomPosition,
} from '@apps/www/src/www/helpers/topNavBottomPositionEmitter';
import useElementBoudingClientRectUpdated from '@apps/www/src/www/hooks/useElementBoudingClientRectUpdated';
import useIsSideMenuRendered from '@apps/www/src/www/hooks/useIsSideMenuRendered';
import usePersistentSetting, {
	PersistentSettingKeys,
} from '@apps/www/src/www/hooks/usePersistentSetting';
import useUserAndBoardPageQuery from '@apps/www/src/www/hooks/useUserAndBoardPageQuery';
import SVNewBoardModal from '@apps/www/src/www/modals/SVNewBoardModal';
import SVLink from '@pkgs/shared-client/components/SVLink';
import SVModal from '@pkgs/shared-client/components/SVModal';
import matchLocation from '@pkgs/shared-client/helpers/matchLocation';
import plural from '@pkgs/shared-client/helpers/plural';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import IconPlus2SVG from '@pkgs/shared-client/img/icon-plus2-inlined.svg';
import BoardOwnershipType from '@pkgs/shared/enums/BoardOwnershipType';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';

const _ToggleButton = () => {
	const [isSideMenuVisible, setIsSideMenuVisible] = usePersistentSetting(
		PersistentSettingKeys.SIDE_MENU_VISIBLE,
	);

	const handleToggleClick = useEventCallback(() => {
		setIsSideMenuVisible(!isSideMenuVisible);
	});

	return (
		<div className="-lg:hidden text-secondary z-30 mb-2 inline-flex h-12 w-[calc(40px+12px)] items-center justify-center pt-[6px]">
			<button
				onClick={handleToggleClick}
				aria-label={isSideMenuVisible ? 'Hide side menu' : 'Show side menu'}
				title={isSideMenuVisible ? 'Hide side menu' : 'Show side menu'}
				className="relative flex h-10 w-10 items-center justify-center rounded-full text-gray-300 transition-colors duration-200 hover:bg-gray-50 hover:bg-opacity-10"
			>
				<div className="relative flex h-5 w-5 flex-col items-center justify-center">
					<span
						className={clsx(
							'absolute block h-[1px] w-4 rounded-full bg-current transition-transform duration-300',
							isSideMenuVisible ? 'rotate-45 transform' : 'translate-y-[-4px]',
						)}
					/>
					<span
						className={clsx(
							'absolute block h-[1px] w-4 rounded-full bg-current transition-transform duration-300',
							isSideMenuVisible ? '-rotate-45 transform' : 'translate-y-[4px]',
						)}
					/>
				</div>
			</button>
		</div>
	);
};

// This dynamically leaves a top space to compensate for the top nav bar whenever it opens in any
// scroll position.
const _TopSpacer = ({ containerRef }: { containerRef: React.RefObject<HTMLDivElement> }) => {
	const spacerRef = useRef<HTMLDivElement>(null);
	const topNavBottomPositionRef = useRef<number>(getTopNavBottomPosition());

	const update = useEventCallback(() => {
		if (!spacerRef.current || !containerRef.current) {
			return;
		}

		const topNavBottomPosition = topNavBottomPositionRef.current;
		const containerBoudingRect = containerRef.current.getBoundingClientRect();
		const spacerPageY = containerBoudingRect.top + window.scrollY;

		const height = Math.max(
			0,
			Math.min(0, window.scrollY - spacerPageY) + topNavBottomPosition,
		);

		spacerRef.current.style.height = `${height}px`;
	});

	const updateTopNavBottomPosition = useEventCallback((position: number) => {
		topNavBottomPositionRef.current = position;

		update();
	});

	useEffect(() => {
		subscribeToTopNavBottomPosition(updateTopNavBottomPosition);

		return () => {
			unsubscribeFromTopNavBottomPosition(updateTopNavBottomPosition);
		};
	}, [updateTopNavBottomPosition]);

	useEffect(() => {
		update();
	}, [update]);

	useEffect(() => {
		window.addEventListener('scroll', update);

		return () => {
			window.removeEventListener('scroll', update);
		};
	}, [update]);

	useElementBoudingClientRectUpdated(containerRef.current, update);

	return <div ref={spacerRef} />;
};

const _BoardItem = React.memo(
	({
		board,
		isSideMenuVisible,
	}: {
		board: BoardFragmentFragment;
		isSideMenuVisible: boolean;
	}) => {
		const router = useRouter();
		const isActive = matchLocation(router.asPath, board.url);

		return (
			<li className="block">
				<SVLink to={board.url} className={clsx('group relative', isActive && 'disabled')}>
					<span
						className={clsx(
							'absolute block h-full w-full rounded-[10px] bg-gray-900 opacity-0 group-hover:opacity-100',
							isActive && 'opacity-100',
						)}
					/>
					<span
						className={clsx(
							'duration-slide absolute block h-full w-full rounded-[10px] border-[1.5px] bg-transparent opacity-0 transition-all ease-out',
							isActive && 'opacity-100',
							isActive && !isSideMenuVisible ? 'border-gray-400' : 'border-gray-900',
						)}
					/>
					<span className="relative flex items-center gap-3 p-[6px]">
						<span
							className="block h-10 w-10 flex-shrink-0 rounded bg-gray-800 bg-cover"
							style={
								board.thumbnails.length > 0
									? {
											backgroundImage: `url(${board.thumbnails[0].image.thumbnail})`,
									  }
									: {}
							}
						/>
						<span className="flex min-w-0 flex-grow flex-col gap-[3px]">
							<span
								className={clsx('type-small truncate', isActive && 'text-primary')}
							>
								{board.name}
							</span>
							<span className="type-small truncate text-gray-500">
								{plural(board.itemsCount, 'Save')}
							</span>
						</span>
					</span>
				</SVLink>
			</li>
		);
	},
);

const _NewBoardItem = React.memo(() => {
	const handleNewBoardClick = useEventCallback(() => {
		SVModal.open(SVNewBoardModal, {
			ownershipType: BoardOwnershipType.USER,
		});
	});

	return (
		<li className="block">
			<button onClick={handleNewBoardClick} className="group relative block w-full">
				<span className="absolute block h-full w-full rounded-[10px] bg-gray-900 opacity-0 group-hover:opacity-100" />
				<span className="relative flex items-center gap-3 p-[6px]">
					<span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded bg-gray-900">
						<IconPlus2SVG className="h-3 w-3 text-gray-400" />
					</span>
					<span className="flex min-w-0 flex-grow flex-col items-start">
						<span className="type-small truncate">New board</span>
					</span>
				</span>
			</button>
		</li>
	);
});

export default function SVSideMenuContainer({ children }: { children: React.ReactNode }) {
	const [isSideMenuVisible] = usePersistentSetting(PersistentSettingKeys.SIDE_MENU_VISIBLE);
	const isSideMenuRendered = useIsSideMenuRendered();
	const containerRef = useRef<HTMLDivElement>(null);
	const { isOwner, user } = useUserAndBoardPageQuery();

	// If user doesn't have boards we don't want to render the side menu at all
	if (!user || !isSideMenuRendered) {
		return <>{children}</>;
	}

	const boards = user.boards.filter((board) => board.ownershipType === BoardOwnershipType.USER);

	return (
		<>
			<div className="flex w-full" ref={containerRef}>
				<div
					className={clsx(
						'-lg:hidden duration-slide flex-shrink-0 transition-all ease-in-out',
						isSideMenuVisible
							? 'w-[var(--page-margin)+240px+12px]'
							: 'w-[var(--page-margin)+40px+12px]',
					)}
				>
					<div
						className={clsx(
							'duration-slide sticky left-0 top-0 h-full max-h-screen w-full pl-[calc(var(--page-margin)-6px)] transition-all ease-in-out',
							isSideMenuVisible
								? 'w-[var(--page-margin)+240px+12px]'
								: 'w-[var(--page-margin)+40px+12px]',
						)}
					>
						<_TopSpacer containerRef={containerRef} />
						<_ToggleButton />
						<div
							className={clsx(
								'duration-slide h-full overflow-y-auto transition-all ease-in-out',
								isSideMenuVisible ? 'w-[calc(240px+12px)]' : 'w-[calc(40px+12px)]',
							)}
						>
							<ul
								className={clsx(
									'duration-slide flex flex-col space-y-[6px] overflow-x-hidden transition-all ease-in-out',
									isSideMenuVisible
										? 'w-[calc(240px+12px)]'
										: 'w-[calc(40px+12px)]',
								)}
							>
								{boards.map((board) => (
									<_BoardItem
										key={board._id}
										board={board}
										isSideMenuVisible={isSideMenuVisible}
									/>
								))}
								{isOwner ? <_NewBoardItem key="new-board" /> : null}
							</ul>
							<div className="h-16" />
						</div>
					</div>
				</div>
				<div className="min-w-0 flex-1">{children}</div>
			</div>
		</>
	);
}
