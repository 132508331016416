import {
	type UserFragmentFragment,
	type UserQueryQuery,
} from '@apps/www/src/__generated__/graphql';
import SVFollowButtonContainer from '@apps/www/src/www/containers/SVFollowButtonContainer';
import SVA from '@pkgs/shared-client/components/SVA';
import SVAvatar from '@pkgs/shared-client/components/SVAvatar';
import SVButton, { SVButtonSIZES, SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVIconButton from '@pkgs/shared-client/components/SVIconButton';
import SVLink from '@pkgs/shared-client/components/SVLink';
import SVPageMargins from '@pkgs/shared-client/components/SVPageMargins';
import SVPageTitle from '@pkgs/shared-client/components/SVPageTitle';
import SVProBadge from '@pkgs/shared-client/components/SVProBadge';
import { preventDefault } from '@pkgs/shared-client/helpers/dom';
import { fixURL, prettyURL } from '@pkgs/shared-client/helpers/format';
import IconMenuSVG from '@pkgs/shared-client/img/icon-menu-three-lines-inlined.svg';
import InstagramIconSVG from '@pkgs/shared-client/img/instagram-inlined.svg';
import TwitterIconSVG from '@pkgs/shared-client/img/twitter-inlined.svg';
import normalizeSocialProfileLink from '@pkgs/shared/helpers/normalizeSocialProfileLink';
import clsx from 'clsx';
import React from 'react';

const _BadgeNotifications = React.memo(() => (
	<span className="theme-dark flex-center type-label bg-brand text-primary duration-over absolute right-0 top-0 h-3 w-3 rounded-full transition ease-out group-hover:brightness-75"></span>
));

const _SmallAvatarItem = React.memo(
	({
		onOpenNav,
		unreadNotificationsCount,
	}: Pick<Props, 'onOpenNav' | 'unreadNotificationsCount'>) => {
		return (
			<SVLink
				className="text-primary relative z-10 flex h-10 w-10 items-center justify-center rounded-full align-middle"
				to={'#'}
				title="Open menu"
				onClick={preventDefault(onOpenNav)}
			>
				<IconMenuSVG />
				{unreadNotificationsCount > 0 ? <_BadgeNotifications /> : ''}
			</SVLink>
		);
	},
);

const _Header = React.memo(
	({
		asOwner,
		user,
		onOpenNav,
		unreadNotificationsCount,
	}: {
		asOwner: boolean;
		user: UserFragmentFragment;
		onOpenNav: (event: React.UIEvent) => void;
		unreadNotificationsCount: number;
	}) => {
		const info = [
			`@${user.username}`,
			user.role,
			user.company && (
				<SVA Component={SVLink} to={user.company.url}>
					{user.company.name}
				</SVA>
			),
		].filter(Boolean);

		const links = [
			user.website && (
				<SVA href={fixURL(user.website)} target="_blank" rel="noopener noreferrer">
					{prettyURL(user.website)}
				</SVA>
			),
			user.instagram && (
				<span className="inline-flex">
					<SVIconButton
						Component="a"
						href={normalizeSocialProfileLink(user.instagram, 'instagram')}
						src={InstagramIconSVG}
						target="_blank"
						rel="noopener noreferrer"
						title={`@${user.instagram}`}
						iconClassName="w-4 h-4 min-w-[16px] min-h-[16px]"
					/>
				</span>
			),
			user.twitter && (
				<span className="inline-flex">
					<SVIconButton
						Component="a"
						href={normalizeSocialProfileLink(user.twitter, 'twitter')}
						src={TwitterIconSVG}
						target="_blank"
						rel="noopener noreferrer"
						title={`@${user.twitter}`}
						iconClassName="w-4 h-4 min-w-[16px] min-h-[16px]"
					/>
				</span>
			),
		].filter(Boolean);

		const itemSeparator = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;

		return (
			<div className="flex flex-col justify-between">
				{/* padding bottom is 53px to match the `SVBoardHeader` height and avoid jumping when navigating */}
				<div className="relative z-10 mb-8 mt-10 flex flex-col items-start space-y-2.5">
					<div className="flex w-full flex-col items-center justify-center">
						<div className="relative mb-2 flex flex-row items-center justify-center">
							<div className="absolute mr-[80vw] sm:hidden">
								<_SmallAvatarItem
									onOpenNav={onOpenNav}
									unreadNotificationsCount={unreadNotificationsCount}
								/>
							</div>
							<SVAvatar
								className="z-index-user-header-avatar border-background -mdp:w-24 relative w-[110px] rounded-full"
								src={user.avatarURL}
							/>
						</div>
						<div className="-sm:mt-2 flex flex-col items-center space-y-2">
							<SVPageTitle
								className={clsx(
									'relative mb-2 flex text-4xl font-normal',
									user.isPro && 'ml-6',
								)}
							>
								<div className="flex min-w-0 flex-row items-center">
									<span className="flex truncate break-words text-gray-50">
										{user.name}
									</span>
									{user.isPro && (
										<SVProBadge
											size={SVProBadge.SIZES.LARGE}
											className="mt-1.5"
										/>
									)}
								</div>
							</SVPageTitle>
							<div className="flex flex-wrap justify-center text-sm text-gray-400">
								{info.map((item, index) => (
									<React.Fragment key={index}>
										{item}
										{index !== info.length - 1 && itemSeparator}
									</React.Fragment>
								))}
							</div>
							<div className="mt-2 flex items-center text-sm text-gray-400">
								{links.map((item, index) => (
									<React.Fragment key={index}>
										{item}
										{index !== links.length - 1 && itemSeparator}
									</React.Fragment>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="-md:mb-10 mb-20 flex flex-col space-y-2">
					{asOwner && (
						<div className="-sm:pb-10 flex justify-center space-x-3">
							<SVButton
								Component={SVLink}
								to="/profile/"
								size={SVButtonSIZES.SMALL}
								className="w-[120px] text-center"
							>
								Edit Profile
							</SVButton>
							<SVButton
								Component={SVLink}
								to="/settings/"
								size={SVButtonSIZES.SMALL}
								use={SVButtonUSES.OUTLINE}
								className="w-[120px] text-center"
							>
								Settings
							</SVButton>
						</div>
					)}
					{user.canFollow && (
						<div className="-sm:pb-10 flex justify-center space-x-3">
							<SVFollowButtonContainer
								userID={user._id}
								canFollow={user.canFollow}
								isFollowing={user.isFollowing}
								isFollowingBack={user.isFollowingBack}
							/>
						</div>
					)}
				</div>
			</div>
		);
	},
);

type Props = {
	asOwner: boolean;
	user: UserQueryQuery['userByUsername'];
	onOpenNav: (event: React.UIEvent) => void;
	unreadNotificationsCount: number;
};

const SVUserHeader = ({ asOwner, user, onOpenNav, unreadNotificationsCount }: Props) => {
	return (
		<SVPageMargins>
			<_Header
				user={user}
				asOwner={asOwner}
				onOpenNav={onOpenNav}
				unreadNotificationsCount={unreadNotificationsCount}
			/>
		</SVPageMargins>
	);
};

export default SVUserHeader;
